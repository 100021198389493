import React from 'react';
import { useUsersContext } from 'contexts/users';
import { Row, Col } from 'react-bootstrap';

export const AccountPage: React.FC = () => {
  const { usersState } = useUsersContext();

  return (
    <>
      <Row className="account">
        <Col className="p-0">
          <div>
            <h1 className="account-header">Konto</h1>
            <hr className="mt-2" />
          </div>
          <div className="account-content">
            <p>
              <strong>ID: </strong>
              {usersState.user.id}
            </p>
            <p>
              <strong>Firma: </strong>
              {usersState.user.company}
            </p>
            <p>
              <strong>Email: </strong>
              {usersState.user.email}
            </p>
            <p>
              <strong>Kodeord: </strong>******
            </p>
            <p>
              <strong>Rolle: </strong>
              {usersState.user.role[0] === 'admin' ? 'Admininistrator' : 'Bruger'}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};
