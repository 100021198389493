import React from 'react';
import { ProductContentProps } from '../types';

const ProductContent: React.FC<React.PropsWithChildren<ProductContentProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');

  return (
    <>
      <td className="d-table-cell product-item product-item-checkbox">
        {props.showButton && (
          <div className="edit-custom-checkbox">
            {props.product.product_variations && props.product.product_variations?.length !== 0 ? (
              <label
                onClick={(event) => {
                  event.stopPropagation();
                  props.setShowVariations && props.setShowVariations(!props.showVariations);
                }}
                className="align-self-center product-item-expand"
              >
                <span>
                  <img src={`${props.showVariations ? '/fa/svgs/solid/angle-up.svg' : '/fa/svgs/solid/angle-down.svg'}`} loading="lazy" alt="check" />
                </span>
              </label>
            ) : (
              <span className="whitespace"></span>
            )}
            <label>
              <input type="checkbox" onClick={(event) => event.stopPropagation()} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                <img
                  className={!props.selected && !props.variationsPartialSelected ? 'hidden' : ''}
                  src={props.variationsPartialSelected === true ? '/fa/svgs/solid/minus.svg' : '/fa/svgs/solid/check.svg'}
                />
              </span>
            </label>
          </div>
        )}
      </td>
      <td className="product-item">
        {props.showImage === true && props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
          <label>
            <img width={65} height={65} src={props.product.vendor_products_image} alt="produkt billede" loading="lazy" />
          </label>
        ) : null}
      </td>
      <td className="d-none d-sm-table-cell product-item">
        <div className="multi-line-wrapper">
          {props.showSku && <label>{props.product.vendor_products_sku}</label>}
          {props.showEan && <small>{props.product.vendor_products_ean}</small>}
        </div>
      </td>
      <td className="product-item">{props.showName && <label>{props.product.vendor_products_name}</label>}</td>
      <td className="d-none d-sm-table-cell product-item">{props.showManufactor && <label>{props.product.vendor_products_manufactor}</label>}</td>
      {props.variationPrice ? (
        <td className="product-item">
          <label>{props.variationPrice()}</label>
        </td>
      ) : (
        <td className="d-none d-sm-table-cell product-item">
          {props.showPrice && <label>{numberFormatter.format(props.product.vendor_products_price)} DKK</label>}
        </td>
      )}
    </>
  );
};

export default ProductContent;
