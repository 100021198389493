import React, { useEffect, useState } from 'react';
import ProductContent from './select-products/product-content';
import VariableProductContent from './select-products/variable-product-content';
import { ProductInterface, ChildVariation } from '../../pages/import-products/shared/types';
import { ProductSelectProps } from './types';
import { dexieDB } from '../../pages/import-products/shared/db';

const SelectVariableProducts: React.FC<React.PropsWithChildren<ProductSelectProps>> = ({ product, selectedProducts }) => {
  const numberFormatter = Intl.NumberFormat('da-DK');
  const [showVariations, setShowVariations] = useState<boolean>(false);
  const [variationsPartialSelected, setVariationsPartialSelected] = useState<boolean>(false);

  const getSelectedMain = (productId: number): ProductInterface | undefined => {
    if (!selectedProducts) {
      return undefined;
    }

    for (const tmpProduct of selectedProducts) {
      if (tmpProduct.vendor_products_id && tmpProduct.vendor_products_id === productId) {
        return tmpProduct;
      }
    }

    return undefined;
  };

  const isMainSelected = (productId: number): boolean => {
    const selectedProduct = getSelectedMain(productId);

    if (!selectedProduct || !selectedProduct.product_variations || !product.product_variations) {
      return false;
    }

    return selectedProduct.product_variations.length === product.product_variations.length;
  };

  const handleMainProductSelect = async (): Promise<void> => {
    if (!product.vendor_products_id) {
      return;
    }

    const updatedProduct: ProductInterface = {
      ...product,
      vendor_products_status: 'draft',
    };

    if (isMainSelected(product.vendor_products_id) === true) {
      await dexieDB.selectedProducts.delete(product.vendor_products_id);
      await dexieDB.editedProducts.delete(product.vendor_products_id);
    } else {
      await dexieDB.selectedProducts.delete(product.vendor_products_id);
      await dexieDB.editedProducts.delete(product.vendor_products_id);

      await dexieDB.selectedProducts.add(updatedProduct);
      await dexieDB.editedProducts.add(updatedProduct);
    }
  };

  const isVariationSelected = (variation: ChildVariation, productHandle: ProductInterface): boolean => {
    if (!productHandle.vendor_products_id) {
      return false;
    }

    const selectedProduct = getSelectedMain(productHandle.vendor_products_id);

    if (!selectedProduct || !selectedProduct.product_variations || !product.product_variations) {
      return false;
    }

    return selectedProduct.product_variations.some((tmpVariation) => tmpVariation.vendor_product_variations_id === variation.vendor_product_variations_id);
  };

  const handleVariationSelect = async (variation: ChildVariation, productHandle: ProductInterface): Promise<void> => {
    if (!productHandle.vendor_products_id) {
      return;
    }

    const selectedProduct = getSelectedMain(productHandle.vendor_products_id);
    const checkVariationSelected = isVariationSelected(variation, productHandle);

    const updatedProduct: ProductInterface = {
      ...productHandle,
      vendor_products_status: 'draft',
    };

    if (!selectedProduct) {
      const tmpProduct = {
        ...updatedProduct,
      };

      if (tmpProduct.product_variations) {
        tmpProduct.product_variations = tmpProduct.product_variations.filter(
          (tmpVariation) => tmpVariation.vendor_product_variations_id === variation.vendor_product_variations_id,
        );
      }

      await dexieDB.selectedProducts.add(tmpProduct);
      await dexieDB.editedProducts.add(tmpProduct);
    } else if (checkVariationSelected === true) {
      const tmpSelectProduct = {
        ...selectedProduct,
      };

      if (tmpSelectProduct.product_variations) {
        tmpSelectProduct.product_variations = tmpSelectProduct.product_variations.filter(
          (tmpVariation) => tmpVariation.vendor_product_variations_id !== variation.vendor_product_variations_id,
        );
      }

      await dexieDB.selectedProducts.update(productHandle.vendor_products_id, tmpSelectProduct);
      await dexieDB.editedProducts.update(productHandle.vendor_products_id, tmpSelectProduct);

      const getMainProduct = await dexieDB.selectedProducts.get(productHandle.vendor_products_id);
      if (getMainProduct && getMainProduct.product_variations && getMainProduct.product_variations.length === 0) {
        await dexieDB.selectedProducts.delete(productHandle.vendor_products_id);
        await dexieDB.editedProducts.delete(productHandle.vendor_products_id);
      }
    } else {
      const tmpSelectProduct = {
        ...selectedProduct,
      };

      if (tmpSelectProduct.product_variations) {
        tmpSelectProduct.product_variations = [...tmpSelectProduct.product_variations, variation];
      }

      await dexieDB.selectedProducts.update(productHandle.vendor_products_id, tmpSelectProduct);
      await dexieDB.editedProducts.update(productHandle.vendor_products_id, tmpSelectProduct);
    }
  };

  const displayPricesOnMainVariant = () => {
    if (product.product_variations) {
      const variantPrices: number[] = product.product_variations.map((variant) => {
        return variant.vendor_product_variations_price;
      });

      if (variantPrices && variantPrices.length > 0) {
        const lowestPrice = Math.min(...variantPrices);
        const highestPrice = Math.max(...variantPrices);

        if (lowestPrice === highestPrice) {
          return `${numberFormatter.format(lowestPrice)} DKK`;
        } else {
          return `Fra ${numberFormatter.format(lowestPrice)} DKK`;
        }
      }
    }
  };

  useEffect(() => {
    const selectedProduct = getSelectedMain(product.vendor_products_id);

    if (selectedProduct && selectedProduct.product_variations && product.product_variations) {
      if (selectedProduct.product_variations?.length < product.product_variations.length && selectedProduct?.product_variations?.length !== 0) {
        setVariationsPartialSelected(true);
      } else {
        setVariationsPartialSelected(false);
      }
    }
  }, [isMainSelected, isVariationSelected]);

  return (
    <>
      <tr
        key={product.vendor_products_id}
        onClick={() => handleMainProductSelect()}
        className={`${isMainSelected(product.vendor_products_id) || variationsPartialSelected ? 'product-table-selected' : ''} ${
          showVariations ? 'product-table-variable' : ''
        }`}
      >
        <ProductContent
          product={product}
          showImage={true}
          showEan={false}
          showSku={false}
          showName={true}
          showManufactor={true}
          showPrice={false}
          showButton={true}
          selected={product.vendor_products_id ? isMainSelected(product.vendor_products_id) : false}
          variationsPartialSelected={variationsPartialSelected}
          setShowVariations={setShowVariations}
          showVariations={showVariations}
          variationPrice={displayPricesOnMainVariant}
        />
      </tr>

      {product.product_variations &&
        product.product_variations.map((variation) => (
          <tr
            key={variation.vendor_product_variations_id}
            onClick={() => handleVariationSelect(variation, product)}
            className={`${isVariationSelected(variation, product) ? 'product-table-selected' : ''} ${showVariations ? '' : 'd-none'} product-table-variable`}
          >
            <VariableProductContent
              variation={variation}
              showImage={true}
              showEan={true}
              showSku={true}
              showName={true}
              showAttribute={true}
              showPrice={true}
              showButton={true}
              selected={isVariationSelected(variation, product)}
            />
          </tr>
        ))}
    </>
  );
};

export default SelectVariableProducts;
