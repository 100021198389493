import fetcher from 'lib/fetcher';
import { UsersIntergrationsInterface } from 'pages/woocommerce-config/types';
import { VendorInterface, ProductInterface } from './pages/import-products/shared/types';
import type { AxiosResponse } from 'axios';
import type { ImageProductData } from 'pages/import-products/sub-pages/confirm-import/types';

export const login = async (email: string, password: string): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/login', 'POST', false, {
    email,
    password,
  });
};

export const createVendor = async (formData: FormData): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/vendor', 'POST', true, formData);
};

export const deleteVendor = async (vendorId: number): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/vendor/delete', 'POST', false, {
    vendor_id: vendorId,
  });
};

export const getVendors = async (categories?: Pick<VendorInterface, 'vendors_industry'>[]): Promise<AxiosResponse | null> => {
  let vendorUrl: string;

  if (categories !== undefined && categories.length !== 0) {
    const urlQuery: {
      categories: string;
    } = {
      categories: JSON.stringify(categories),
    };

    vendorUrl = `/vendor/vendors/?${new URLSearchParams(urlQuery).toString()}`;
  } else {
    vendorUrl = `/vendor/vendors`;
  }

  return await fetcher(process.env.REACT_APP_API_URL + vendorUrl, 'GET');
};

export const getVendorIndustries = async (searchString?: string): Promise<AxiosResponse | null> => {
  let vendorUrl: string;

  if (searchString !== undefined && searchString.length !== 0) {
    const urlQuery: {
      search: string;
    } = {
      search: searchString,
    };

    vendorUrl = `/vendor/vendorIndustries/?${new URLSearchParams(urlQuery).toString()}`;
  } else {
    vendorUrl = `/vendor/vendorIndustries`;
  }

  return await fetcher(process.env.REACT_APP_API_URL + vendorUrl, 'GET');
};

export const getVendorProductsCategories = async (
  sortingFilter: string,
  searchFilter: string,
  selectedVendors?: number[],
  currentPage?: number,
  limit?: number,
): Promise<AxiosResponse | null> => {
  const urlQuery: {
    sort?: string;
    search?: string;
    vendorIds?: string;
    currentPage?: string;
    limit?: string;
  } = {};

  if (selectedVendors !== undefined) {
    urlQuery.vendorIds = selectedVendors
      .map((id) => {
        return id.toString();
      })
      .toString();
  }

  if (sortingFilter.length !== 0) {
    urlQuery.sort = sortingFilter;
  }

  if (searchFilter.length !== 0) {
    urlQuery.search = searchFilter;
  }

  if (currentPage !== undefined) {
    urlQuery.currentPage = (currentPage - 1).toString();
  }

  if (limit !== undefined) {
    urlQuery.limit = limit.toString();
  }

  return await fetcher(process.env.REACT_APP_API_URL + `/vendor/categories/?${new URLSearchParams(urlQuery).toString()}`, 'GET');
};

export const getVendorProducts = async (
  searchFilter: string,
  selectedVendors?: number[],
  selectedCategories?: string[],
  currentPage?: number,
  limit?: number,
  sortOrder?: string,
  sortedField?: string,
): Promise<AxiosResponse | null> => {
  const urlQuery: {
    search?: string;
    vendorIds?: string;
    categories?: string;
    currentPage?: string;
    limit?: string;
    sortOrder?: string;
    sortedField?: string;
  } = {};

  if (selectedVendors !== undefined) {
    urlQuery.vendorIds = selectedVendors
      .map((id) => {
        return id.toString();
      })
      .toString();
  }

  if (selectedCategories !== undefined) {
    urlQuery.categories = selectedCategories
      .map((id) => {
        return id.toString();
      })
      .toString();
  }

  if (sortOrder !== undefined) {
    urlQuery.sortOrder = sortOrder;
  }
  if (sortedField !== undefined) {
    urlQuery.sortedField = sortedField;
  }

  if (searchFilter.length !== 0) {
    urlQuery.search = searchFilter;
  }

  if (currentPage !== undefined) {
    urlQuery.currentPage = (currentPage - 1).toString();
  }

  if (limit !== undefined) {
    urlQuery.limit = limit.toString();
  }

  if (searchFilter.length === 0) {
    return await fetcher(process.env.REACT_APP_API_URL + `/vendor/products/?${new URLSearchParams(urlQuery).toString()}`, 'GET');
  } else {
    return await fetcher(process.env.REACT_APP_API_URL + `/vendor/products/search/?${new URLSearchParams(urlQuery).toString()}`, 'GET');
  }
};

export const getWebshopProductCategories = async (): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/categories', 'GET');
};

export const getWebshopProductTags = async (): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/tags', 'GET');
};

export const getWebshopConfig = async (): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/config', 'GET');
};

export const setWebshopConfig = async (webshopConfig: UsersIntergrationsInterface): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/config', 'POST', false, webshopConfig);
};

export const addWebshopProduct = async (product: ProductInterface[] | ProductInterface): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/products', 'POST', false, product);
};

export const updateWebshopProductImages = async (images: ImageProductData[] | ImageProductData): Promise<AxiosResponse | null> => {
  return await fetcher(process.env.REACT_APP_API_URL + '/webshop/products/images', 'PUT', false, images);
};
