import React, { useEffect, useState } from 'react';
import { categoryInterface } from '../../shared/types';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Api
import { getVendorProductsCategories } from 'Api';
//Styles
import { Col, Row, Button, Spinner, Table } from 'react-bootstrap';
import Search from 'components/search';

export const SelectCategoriesPage: React.FC = () => {
  const [categories, setCategories] = useState<categoryInterface[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortingFilter, setSortingFilter] = useState<string>('asc');
  const [currentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [visibleCount, setVisibleCount] = useState<number>(20);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const selectedVendors = useLiveQuery(() => dexieDB.vendors.toArray());

  const selectedCategories = useLiveQuery(() => dexieDB.categories.toArray());

  useEffect(() => {
    if (!selectedVendors || selectedVendors.length === 0) {
      return;
    }

    const getVendorProductsCategoriesHandler = async (): Promise<void> => {
      const vendorIds: number[] = [];

      if (selectedVendors) {
        for await (const vendor of selectedVendors) {
          if (vendor.vendors_id) {
            vendorIds.push(vendor.vendors_id);
          }
        }
      }

      const response = await getVendorProductsCategories(sortingFilter, searchFilter.trim(), vendorIds, currentPage, totalResults);

      if (response) {
        setCategories(
          response.data.results.map((product: categoryInterface) => {
            return {
              vendor_products_category: product.vendor_products_category,
              product_count: product.product_count,
            };
          }),
        );
        setTotalResults(response.data.total);
      }
    };

    getVendorProductsCategoriesHandler();
  }, [selectedVendors, searchFilter, sortingFilter, currentPage, totalResults]);

  const isSelected = (category: string): boolean => {
    if (selectedCategories) {
      return selectedCategories.some((tmpCategory) => tmpCategory.vendor_products_category === category);
    }

    return false;
  };

  const handleCategorySelect = async (category: string): Promise<void> => {
    if (isSelected(category) === true) {
      await dexieDB.categories.delete(category);
    } else {
      await dexieDB.categories.add({ vendor_products_category: category });
    }
  };

  const handleSelectAll = async (): Promise<void> => {
    setIsAdding(true);

    const allSelected = categories.every((category) => isSelected(category.vendor_products_category));

    if (allSelected) {
      await dexieDB.categories.clear();
    } else {
      const bulkCreate: Pick<categoryInterface, 'vendor_products_category'>[] = categories
        .filter((category) => !isSelected(category.vendor_products_category))
        .map((category) => ({ vendor_products_category: category.vendor_products_category }));

      await dexieDB.categories.bulkAdd(bulkCreate);
    }

    setIsAdding(false);
  };

  useEffect(() => {
    if (categories.length === selectedCategories?.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [categories, isSelected]);

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(totalResults);
  };

  return (
    <>
      <div className="heading-intro">
        <h1>Kategori</h1>
        <hr className="mt-2" />
      </div>

      <Row className="mt-5">
        <Col sm={4}>
          <Search placeholder={'Søg efter kategori'} value={searchFilter} onChangeFunction={(e) => setSearchFilter(e.target.value)} />
        </Col>
      </Row>

      <Table className="mt-4 product-table">
        <thead>
          <tr className="mt-5 mb-4">
            <th className="d-table-cell" onClick={handleSelectAll}>
              <div className="edit-custom-checkbox big-checkbox ms-1">
                <label>
                  <input type="checkbox" onClick={(event) => event.stopPropagation()} />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    {isAdding ? (
                      <Spinner animation="border" role="status" size="sm"></Spinner>
                    ) : (
                      <img className={isAllSelected ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" />
                    )}
                  </span>
                </label>
              </div>
            </th>
            <th className="d-table-cell sorting-label" onClick={() => (sortingFilter === 'asc' ? setSortingFilter('desc') : setSortingFilter('asc'))}>
              <label>Kategori</label>
              {sortingFilter === 'asc' ? <img src="/fa/svgs/solid/sort-up.svg" /> : <img src="/fa/svgs/solid/sort-down.svg" />}
            </th>
            <th className="d-table-cell">
              <label>Antal produkter</label>
            </th>
          </tr>
        </thead>

        <tbody>
          {categories.slice(0, visibleCount).map((category) => (
            <tr
              key={category.vendor_products_category}
              onClick={() => handleCategorySelect(category.vendor_products_category)}
              className={isSelected(category.vendor_products_category) ? 'product-table-selected' : ''}
            >
              <td className="product-item">
                <div className="edit-custom-checkbox ms-1">
                  <label>
                    <input type="checkbox" onClick={(event) => event.stopPropagation()} />
                    <span className="edit-custom-checkbox-check" aria-hidden="true">
                      <img className={isSelected(category.vendor_products_category) ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" />
                    </span>
                  </label>
                </div>
              </td>
              <td className="product-item">
                <p>{category.vendor_products_category}</p>
              </td>
              <td className="product-item">
                <p>{category.product_count}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < totalResults && (
            <>
              <Button className="show-more" onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2 show-more" onClick={handleShowAll} variant="primary">
                Vis alle ({totalResults})
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
