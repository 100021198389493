import React, { useEffect, useState } from 'react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Models
import { ProductInterface, CategoryInterface, ChildVariation, TagInterface, ExtraAttributes } from '../../shared/types';
import { Button, Col, Row, Table } from 'react-bootstrap';
//API
import { getWebshopProductCategories, getWebshopProductTags } from 'Api';
//Components
import { SimpleEditProducts, VariableEditProducts, SimpleProductModal } from 'components/products';
import { useSelectedProducts } from 'contexts/selected-products';
import CustomToast from 'components/toast';
import Search from 'components/search';

export const EditProductsPage: React.FC = () => {
  const [editedProducts, setEditedProducts] = useState<ProductInterface[]>([]);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [productUnderEdit, setProductUnderEdit] = useState<ProductInterface | null>();
  const [webshopCategories, setWebshopCategories] = useState<CategoryInterface[]>([]);
  const [webshopTags, setWebshopTags] = useState<TagInterface[]>([]);
  const [filteredTags, setFilteredTags] = useState<TagInterface[]>([]);
  const [sortField, setSortField] = useState<keyof ProductInterface | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>('');
  const [tagSearchTerm, setTagSearchTerm] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [currentPage] = useState<number>(1);
  const [resultsPerPage] = useState<number>(20);
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingTags, setIsLoadingTags] = useState(true);
  const [visibleCount, setVisibleCount] = useState<number>(20);
  const [massedit, setMassedit] = useState<boolean>(false);
  const [markAll, setMarkAll] = useState<boolean>(false);
  const [editedCount, setEditedCount] = useState<number>(0);
  const [categoryTree, setCategoryTree] = useState<CategoryInterface[]>([]);

  const [vejlPrice, setVejlPrice] = useState<string>('');
  const [discountPrice, setDiscountPrice] = useState<string>('');
  const [costPrice, setCostPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [discountCost, setDiscountCost] = useState<string>('');
  const [discountCostType, setDiscountCostType] = useState<string>('');
  const [discountVejl, setDiscountVejl] = useState<string>('');
  const [discountVejlType, setDiscountVejlType] = useState<string>('');
  const [stock, setStock] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [attributes, setAttributes] = useState<ExtraAttributes[]>([]);
  const [categorySelected, setCategorySelected] = useState<CategoryInterface[]>([]);
  const [tagSelected, setTagSelected] = useState<CategoryInterface[]>([]);
  const [categoryShared, setCategoryShared] = useState<CategoryInterface[]>([]);
  const [tagShared, setTagShared] = useState<TagInterface[]>([]);

  const [toastActive, setToastActive] = useState(false);

  const editedProductsCount = useLiveQuery(() => dexieDB.editedProducts.count());
  const allEditedProducts = useLiveQuery(() => {
    return dexieDB.editedProducts.toArray().then((products) => {
      if (sortField === 'vendor_products_sku') {
        products.sort((a, b) => {
          const skuA = a.product_variations?.[0]?.vendor_product_variations_sku ?? a.vendor_products_sku;
          const skuB = b.product_variations?.[0]?.vendor_product_variations_sku ?? b.vendor_products_sku;

          if (sortOrder === 'asc') {
            return skuA.localeCompare(skuB);
          } else {
            return skuB.localeCompare(skuA);
          }
        });
      } else if (sortField === 'vendor_products_price') {
        products.sort((a, b) => {
          let priceA;
          let priceB;

          // Sortere på mindste prisen af alle varianter i et produkt
          if (a.product_variations && a.product_variations?.length > 0) {
            const pricesA = a.product_variations.map((variation) => {
              return variation.vendor_product_variations_price;
            });
            priceA = Math.min(...pricesA);
          }

          if (b.product_variations && b.product_variations?.length > 0) {
            const pricesB = b.product_variations.map((variation) => {
              return variation.vendor_product_variations_price;
            });
            priceB = Math.min(...pricesB);
          }

          priceA ? priceA : (priceA = a.vendor_products_price);
          priceB ? priceB : (priceB = b.vendor_products_price);

          if (sortOrder === 'asc') {
            return priceA - priceB;
          } else {
            return priceB - priceA;
          }
        });
      } else if (sortField) {
        products.sort((a, b) => {
          if (sortOrder === 'asc') {
            return String(a[sortField]).localeCompare(String(b[sortField]));
          } else {
            return String(b[sortField]).localeCompare(String(a[sortField]));
          }
        });
      }

      return products;
    });
  }, [sortField, sortOrder]);

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field as keyof ProductInterface);
      setSortOrder('asc');
    }

    localStorage.setItem('editSortField', field);
    localStorage.setItem('editSortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const savedSortField = localStorage.getItem('editSortField');
    const savedSortOrder = localStorage.getItem('editSortOrder');

    if (savedSortField) {
      setSortField(savedSortField as keyof ProductInterface);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as 'asc' | 'desc');
    }
  }, []);

  useEffect(() => {
    if (!allEditedProducts) {
      return;
    }

    setEditedProducts(allEditedProducts);
  }, [allEditedProducts, currentPage, resultsPerPage]);

  useEffect(() => {
    let isMounted = true;

    const getWebshopProductCategoriesHandler = async (): Promise<void> => {
      setIsLoadingCategories(true);
      const response = await getWebshopProductCategories();

      if (isMounted) {
        if (response) {
          setWebshopCategories(response.data);
        }
      }

      setIsLoadingCategories(false);
    };

    const getWebshopProductTagsHandler = async (): Promise<void> => {
      setIsLoadingTags(true);
      const response = await getWebshopProductTags();
      if (isMounted) {
        if (response) {
          setWebshopTags(response.data);
        }
      }

      setIsLoadingTags(false);
    };

    getWebshopProductCategoriesHandler();
    getWebshopProductTagsHandler();

    return () => {
      isMounted = false;
    };
  }, []);

  // Håndter kategori på masseredigering
  useEffect(() => {
    setCategoryTree(categoryNesting(webshopCategories));

    if (categorySearchTerm) {
      const matchesSearchTerm = webshopCategories.filter((category) => {
        return category.name?.toLowerCase().includes(categorySearchTerm.toLowerCase());
      });

      setCategoryTree(matchesSearchTerm);
    }
  }, [webshopCategories, categorySearchTerm]);

  useEffect(() => {
    setFilteredTags(webshopTags);

    if (tagSearchTerm) {
      const matchesSearchTerm = webshopTags.filter((tag) => {
        return tag.name?.toLowerCase().includes(tagSearchTerm.toLowerCase());
      });

      setFilteredTags(matchesSearchTerm);
    }
  }, [webshopTags, tagSearchTerm]);

  // Filtrerer både på kategori og søgning
  useEffect(() => {
    if (!allEditedProducts) return;

    const filteredProducts = allEditedProducts.filter((product) => {
      const matchesSearchTerm = searchTerm
        ? product.vendor_products_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_ean?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_price?.toString().includes(searchTerm.toLowerCase())
        : true;

      const matchesCategory = category
        ? Array.isArray(product.vendor_products_category)
          ? product.vendor_products_category.some((cat) => {
              if (cat && typeof cat === 'object' && cat.id) {
                return parseInt(`${cat.id}`) === parseInt(`${category}`);
              }

              return false;
            })
          : product.vendor_products_category
          ? product.vendor_products_category.includes(category)
          : false
        : true;

      return matchesSearchTerm && matchesCategory;
    });

    // Opdaterer state med de produkter, som skal vises på den nuværende side
    setEditedProducts(filteredProducts);
  }, [allEditedProducts, currentPage, resultsPerPage, searchTerm, category]);

  useEffect(() => {
    if (selectedProducts) {
      const categoryCount: Record<string, { category: CategoryInterface; count: number }> = {};

      selectedProducts.forEach((product) => {
        if (Array.isArray(product.vendor_products_category)) {
          (product.vendor_products_category as CategoryInterface[]).forEach((category) => {
            if (categoryCount[category.id]) {
              categoryCount[category.id].count += 1;
            } else {
              categoryCount[category.id] = { category, count: 1 };
            }
          });
        }
      });

      const sharedCategories = Object.values(categoryCount)
        .filter((entry) => entry.count === selectedProducts.length)
        .map((entry) => entry.category);
      setCategoryShared(sharedCategories);

      const selectedCategories = Object.values(categoryCount).map((entry) => entry.category);
      setCategorySelected(selectedCategories);

      const tagCount: Record<string, { tag: TagInterface; count: number }> = {};

      selectedProducts.forEach((product) => {
        if (Array.isArray(product.vendor_products_tags)) {
          (product.vendor_products_tags as TagInterface[]).forEach((tag) => {
            if (tagCount[tag.id]) {
              tagCount[tag.id].count += 1;
            } else {
              tagCount[tag.id] = { tag, count: 1 };
            }
          });
        }
      });

      const sharedTags = Object.values(tagCount)
        .filter((entry) => entry.count === selectedProducts.length)
        .map((entry) => entry.tag);
      setTagShared(sharedTags);

      const selectedTag = Object.values(tagCount).map((entry) => entry.tag);
      setTagSelected(selectedTag);
    }
  }, [selectedProducts]);

  function clearAll() {
    setVejlPrice('');
    setDiscountVejl('');
    setDiscountVejlType('');
    setDiscountPrice('');
    setDiscount('');
    setDiscountType('');
    setCostPrice('');
    setDiscountCost('');
    setDiscountCostType('');
    setStock('');
    setStatus('');
    setSearchTerm('');
    setCategorySearchTerm('');
    setCategory('');
    setCategorySelected([]);
    setAttributes([]);
    setSelectedProducts([]);
    setMarkAll(false);
  }

  const handleAddAttribute = () => {
    const newAttribute = { name: '', value: '' };
    setAttributes((currentAttributes) => [...currentAttributes, newAttribute]);
  };

  const handleRemoveAttribute = (index: number) => {
    setAttributes((currentAttributes) => currentAttributes.filter((_, currentIndex) => currentIndex !== index));
  };

  const handleAttributeChange = (index: number, key: keyof ExtraAttributes, value: string) => {
    setAttributes((currentAttributes) => currentAttributes.map((attr, i) => (i === index ? { ...attr, [key]: value } : attr)));
  };

  function handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setCategory(event.target.value);
  }

  const handleCategorySelected = (category: CategoryInterface) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isCategoryShared = prevSelectedProducts.every(
        (product) =>
          Array.isArray(product.vendor_products_category) &&
          (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
      );

      const isCategoryPartial =
        prevSelectedProducts.some(
          (product) =>
            Array.isArray(product.vendor_products_category) &&
            (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
        ) && !isCategoryShared;

      const updatedProducts = prevSelectedProducts.map((product) => {
        let categories = product.vendor_products_category;

        if (!Array.isArray(categories)) {
          categories = typeof categories === 'string' ? [categories] : [];
        }

        let updatedCategories = (categories as CategoryInterface[]).filter((productCategory) =>
          webshopCategories.some((webshopCategory) => webshopCategory.id === productCategory.id),
        );

        updatedCategories =
          isCategoryShared || isCategoryPartial
            ? updatedCategories.filter((productCategory) => productCategory.id !== category.id)
            : [...updatedCategories, category];

        return {
          ...product,
          vendor_products_category: updatedCategories,
        };
      });

      if (isCategoryShared || isCategoryPartial) {
        setCategorySelected((prev) => prev.filter((selectedCategory) => selectedCategory.id !== category.id));
      } else {
        setCategorySelected((prev) => [...prev, category]);
      }

      return updatedProducts;
    });
  };

  const handleTagSelected = (tag: TagInterface) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isTagShared = prevSelectedProducts.every(
        (product) =>
          Array.isArray(product.vendor_products_tags) && (product.vendor_products_tags as TagInterface[]).some((productTag) => productTag.id === tag.id),
      );

      const isTagPartial =
        prevSelectedProducts.some(
          (product) =>
            Array.isArray(product.vendor_products_tags) && (product.vendor_products_tags as TagInterface[]).some((productTag) => productTag.id === tag.id),
        ) && !isTagShared;

      const updatedProducts = prevSelectedProducts.map((product) => {
        let tags = product.vendor_products_tags;

        if (!Array.isArray(tags)) {
          tags = typeof tags === 'string' ? [tags] : [];
        }

        let updatedTags = (tags as TagInterface[]).filter((productTag) => webshopTags.some((webshopTag) => webshopTag.id === productTag.id));

        updatedTags = isTagShared || isTagPartial ? updatedTags.filter((productTag) => productTag.id !== tag.id) : [...updatedTags, tag];

        return {
          ...product,
          vendor_products_tags: updatedTags,
        };
      });

      if (isTagShared || isTagPartial) {
        setTagSelected((prev) => prev.filter((selectedTag) => selectedTag.id !== tag.id));
      } else {
        setTagSelected((prev) => [...prev, tag]);
      }

      return updatedProducts;
    });
  };

  const categoryNesting = (categories: CategoryInterface[]) => {
    const categoryMap: { [key: string]: CategoryInterface } = {};

    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const tree: CategoryInterface[] = [];

    categories.forEach((category) => {
      if (category.parent === 0) {
        tree.push(categoryMap[category.id]);
      } else if (category.parent && categoryMap[category.parent]) {
        (categoryMap[category.parent].children as CategoryInterface[]).push(categoryMap[category.id]);
      }
    });
    return tree;
  };

  const categoryView = () => {
    const renderCategoryView = (category: CategoryInterface) => {
      const isCategoryShared = categoryShared.some((sharedCategory) => sharedCategory.id === category.id);
      const isCategoryPartial = categorySelected.some((selectedCategory) => selectedCategory.id === category.id) && !isCategoryShared;
      let categoryParent;

      if (categorySearchTerm) {
        categoryParent = webshopCategories.find((parent) => Number(parent.id) === category.parent);
      }
      let iconSrc = '';
      if (isCategoryShared) {
        iconSrc = '/fa/svgs/solid/check.svg';
      } else if (isCategoryPartial) {
        iconSrc = '/fa/svgs/solid/minus.svg';
      }
      return (
        <React.Fragment key={category.id}>
          <div className="edit-custom-checkbox">
            <label>
              <input type="checkbox" value={category.id} checked={isCategoryShared} onChange={() => handleCategorySelected(category)} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                {iconSrc && <img src={iconSrc} loading="lazy" alt="check" />}
              </span>
            </label>
            <span className="edit-custom-checkbox-text">
              {category.name}
              {categoryParent && <span>{` /${categoryParent.name}`}</span>}
            </span>
          </div>
          {category.children && category.children.length > 0 && (
            <div className="child-categories ps-3">{category.children.map((childCategory) => renderCategoryView(childCategory))}</div>
          )}
        </React.Fragment>
      );
    };

    return <div>{categoryTree.map((category) => renderCategoryView(category))}</div>;
  };

  const tagView = () => {
    const renderTagView = (tag: TagInterface) => {
      const isTagShared = tagShared.some((sharedTag) => sharedTag.id === tag.id);
      const isTagPartial = tagSelected.some((selectedTag) => selectedTag.id === tag.id) && !isTagShared;

      let iconSrc = '';
      if (isTagShared) {
        iconSrc = '/fa/svgs/solid/check.svg';
      } else if (isTagPartial) {
        iconSrc = '/fa/svgs/solid/minus.svg';
      }
      return (
        <React.Fragment key={tag.id}>
          <div className="edit-custom-checkbox">
            <label>
              <input type="checkbox" value={tag.id} checked={isTagShared} onChange={() => handleTagSelected(tag)} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                {iconSrc && <img src={iconSrc} loading="lazy" alt="check" />}
              </span>
            </label>
            <span className="edit-custom-checkbox-text">{tag.name}</span>
          </div>
        </React.Fragment>
      );
    };

    return <div>{filteredTags.map((tag) => renderTagView(tag))}</div>;
  };

  const CategoryOptions = () => {
    const renderCategoryOptions = (category: CategoryInterface, depth = 0) => {
      const prefix = '-'.repeat(depth);

      return (
        <React.Fragment key={category.id}>
          <option value={category.id}>
            {prefix}
            {category.name}
          </option>
          {category.children && category.children.length > 0 && category.children.map((childCategory) => renderCategoryOptions(childCategory, depth + 1))}
        </React.Fragment>
      );
    };

    return (
      <>
        <option value="">Alle kategorier</option>
        {categoryTree.map((category) => renderCategoryOptions(category))}
      </>
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCategorySearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategorySearchTerm(event.target.value);
  };

  const handleTagSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagSearchTerm(event.target.value);
  };

  const handleMarkAll = () => {
    if (!markAll) {
      setMarkAll(true);
      setSelectedProducts(editedProducts);
    } else {
      setMarkAll(false);
      setSelectedProducts([]);
    }
  };

  const activateToast = () => {
    setToastActive(true);
    setTimeout(() => {
      setToastActive(false);
    }, 4000);
  };

  const performMassChange = async () => {
    if (!selectedProducts.length) {
      return;
    }

    // Opdater kategorier
    const commonCategories = categorySelected.filter((category) =>
      selectedProducts.every(
        (product) =>
          Array.isArray(product.vendor_products_category) &&
          (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
      ),
    );

    const updatedProducts = selectedProducts.map((product) => {
      const updatedProduct = { ...product };

      const existingCategories: CategoryInterface[] = Array.isArray(updatedProduct.vendor_products_category)
        ? [...(updatedProduct.vendor_products_category as CategoryInterface[])]
        : [];

      const updatedCategories = [
        ...existingCategories,
        ...commonCategories.filter((commonCategory) => !existingCategories.some((existingCategory) => existingCategory.id === commonCategory.id)),
      ];

      updatedProduct.vendor_products_category = updatedCategories;

      // Opdater lager
      updatedProduct.vendor_products_stock = stock ? parseInt(stock, 10) : product.vendor_products_stock;

      if (product.product_variations && product.product_variations.length > 0) {
        updatedProduct.product_variations = product.product_variations.map((variation) => ({
          ...variation,
          vendor_product_variations_stock: stock ? parseInt(stock, 10) : variation.vendor_product_variations_stock,
        }));
      }

      // Opdater status
      updatedProduct.vendor_products_status = status ? status : product.vendor_products_status;

      // Opdater priser
      if (discountVejlType === 'ændring') {
        updatedProduct.vendor_products_price = vejlPrice ? parseFloat(vejlPrice) : product.vendor_products_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_price: vejlPrice ? parseFloat(vejlPrice) : variation.vendor_product_variations_price,
          }));
        }
      }

      if (discountType === 'ændring') {
        updatedProduct.vendor_products_sales_price = discountPrice ? parseFloat(discountPrice) : product.vendor_products_sales_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_sales_price: discountPrice ? parseFloat(discountPrice) : variation.vendor_product_variations_sales_price,
          }));
        }
      }

      if (discountCostType === 'ændring') {
        updatedProduct.vendor_products_cost_price = costPrice ? parseFloat(costPrice) : product.vendor_products_cost_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_cost_price: costPrice ? parseFloat(costPrice) : variation.vendor_product_variations_cost_price,
          }));
        }
      }

      const applyDiscount = (price: number, discountPercentage: number, isIncrease: boolean) => {
        const result = isIncrease ? price * (1 + discountPercentage) : price * (1 - discountPercentage);

        return Math.round(result * 100) / 100;
      };

      if (discountVejlType === 'stigning' || discountVejlType === 'prisfald') {
        const discountPercentage = discountVejl ? parseFloat(discountVejl) / 100 : 0;
        const isIncrease = discountVejlType === 'stigning';

        updatedProduct.vendor_products_price = applyDiscount(product.vendor_products_price, discountPercentage, isIncrease);

        if (product.product_variations && product.product_variations?.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            variation.vendor_product_variations_price = applyDiscount(variation.vendor_product_variations_price, discountPercentage, isIncrease);
            return variation;
          });
        }
      }

      if ((discountType === 'stigning' && product.vendor_products_sales_price) || (discountType === 'prisfald' && product.vendor_products_sales_price)) {
        const discountPercentage = discount ? parseFloat(discount) / 100 : 0;
        const isIncrease = discountType === 'stigning';

        updatedProduct.vendor_products_sales_price = applyDiscount(product.vendor_products_sales_price, discountPercentage, isIncrease);

        if (product.product_variations && product.product_variations?.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_sales_price) {
              variation.vendor_product_variations_sales_price = applyDiscount(variation.vendor_product_variations_sales_price, discountPercentage, isIncrease);
            }
            return variation;
          });
        }
      }

      if ((discountCostType === 'stigning' && product.vendor_products_cost_price) || (discountCostType === 'prisfald' && product.vendor_products_cost_price)) {
        const discountPercentage = discountCost ? parseFloat(discountCost) / 100 : 0;
        const isIncrease = discountCostType === 'stigning';

        updatedProduct.vendor_products_cost_price = applyDiscount(product.vendor_products_cost_price, discountPercentage, isIncrease);

        if (product.product_variations && product.product_variations?.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_cost_price) {
              variation.vendor_product_variations_cost_price = applyDiscount(variation.vendor_product_variations_cost_price, discountPercentage, isIncrease);
            }
            return variation;
          });
        }
      }

      // Opdater attributer
      const attributeData: ExtraAttributes[] = [];

      if (updatedProduct.vendor_products_properties && typeof updatedProduct.vendor_products_properties === 'string') {
        const properties = JSON.parse(updatedProduct.vendor_products_properties);

        for (const property of properties) {
          for (const [key, value] of Object.entries(property)) {
            attributeData.push({
              name: key,
              value: value as string,
            });
          }
        }

        attributeData.push(...attributes);

        const product_properties = attributeData.map((attribute) => {
          return { [attribute.name]: attribute.value };
        });

        updatedProduct.vendor_products_properties = JSON.stringify(product_properties);
      } else {
        const product_properties = attributes.map((attribute) => {
          return { [attribute.name]: attribute.value };
        });

        updatedProduct.vendor_products_properties = JSON.stringify(product_properties);
      }

      return updatedProduct;
    });
    setSelectedProducts(updatedProducts);

    // Opdatering af Dexie med de ændrede produkter
    for (const product of updatedProducts) {
      if (product.vendor_products_id !== undefined) {
        await dexieDB.editedProducts.update(product.vendor_products_id, product);
      }
    }

    setSelectedProducts(updatedProducts);
    setEditedCount(updatedProducts.length);
    setEditedProducts(updatedProducts);
    activateToast();
    clearAll();
  };

  function calculateTotalMarked() {
    let total = 0;

    selectedProducts.forEach((product) => {
      total += 1;

      if (product.product_variations) {
        total += product.product_variations.length;
      }
    });

    return total;
  }

  const removeSelectedProducts = () => {
    selectedProducts.forEach((product) => {
      dexieDB.selectedProducts.delete(product.vendor_products_id);
      dexieDB.editedProducts.delete(product.vendor_products_id);
    });
  };

  const removeProduct = (product: ProductInterface, variation?: ChildVariation) => {
    if (!variation) {
      dexieDB.selectedProducts.delete(product.vendor_products_id);
      dexieDB.editedProducts.delete(product.vendor_products_id);
    }
    if (variation) {
      const updatedVariations = product.product_variations?.filter(
        (v: ChildVariation) => v.vendor_product_variations_id !== variation.vendor_product_variations_id,
      );

      dexieDB.selectedProducts.update(product.vendor_products_id, { product_variations: updatedVariations });
      dexieDB.editedProducts.update(product.vendor_products_id, { product_variations: updatedVariations });
    }
  };

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(editedProductsCount ? editedProductsCount : 0);
  };

  const handleShowMassedit = () => {
    setMassedit((prev) => !prev);
  };

  const getPrevAndNextProduct = (selectedProduct: ProductInterface | null | undefined) => {
    if (!selectedProduct || editedProducts.length === 0) {
      return { prevProduct: null, nextProduct: null };
    }

    const productIndex = editedProducts.findIndex((product) => product.vendor_products_id === selectedProduct.vendor_products_id);

    if (productIndex === -1) {
      return { prevProduct: null, nextProduct: null };
    }

    const prevProduct = productIndex > 0 ? editedProducts[productIndex - 1] : null;
    const nextProduct = productIndex < editedProducts.length - 1 ? editedProducts[productIndex + 1] : null;

    return { prevProduct, nextProduct };
  };

  return (
    <>
      <div className="toast-container">
        <CustomToast
          show={toastActive}
          message={editedCount + (editedCount === 1 ? ' produkt' : ' produkter') + ' blev opdateret.'}
          onClose={() => setToastActive(false)}
          duration={3500}
          title={'Ændringerne er gemt.'}
          type={'toast-success'}
        />
      </div>

      <div className="heading-intro">
        <h1>Produkter</h1>
        <hr className="mt-2" />
        <span>{'Total af ' + calculateTotalMarked() + ' produkter er markeret.'}</span>
      </div>

      <Row className="edit-options">
        <Col xs={4} className="p-0 pe-2">
          <Search placeholder={'Søg efter produkt'} value={searchTerm} onChangeFunction={handleSearchChange} />
        </Col>

        <Col xs={'auto'} className="p-0 pe-2">
          <select
            className="form-select edit-options-category"
            aria-label="Category filter"
            value={category}
            onChange={handleCategoryChange}
            disabled={isLoadingCategories}
          >
            {isLoadingCategories ? <option>Henter Kategorier...</option> : CategoryOptions()}
          </select>
        </Col>
        <Col xs={'auto'} className="p-0">
          <div>
            <Button onClick={handleShowMassedit}>Masseredigering</Button>
          </div>
        </Col>
      </Row>
      {massedit && (
        <div className="mass-edit">
          <Row>
            <div className="mass-edit-header">
              <p>Masseredigering</p>
              <Button className="mass-edit-close" variant="secondary" onClick={handleShowMassedit}>
                <img src="/fa/svgs/solid/xmark.svg" />
              </Button>
            </div>
            <Col>
              <p className="mass-edit-subheader">
                Kategorier
                <button className="mass-edit-subheader-tooltip">
                  <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                  <span>Vælg de kategorier, produktet skal vises i på hjemmesiden.</span>
                </button>
              </p>
              <div className="mass-edit-search">
                <Search placeholder={'Søg efter kategori'} value={categorySearchTerm} onChangeFunction={handleCategorySearchChange}></Search>
              </div>
              <div className="mass-edit-checkboxes">
                {isLoadingCategories ? (
                  <p>Loading</p>
                ) : selectedProducts && selectedProducts.length ? (
                  categoryView()
                ) : (
                  <p className="mass-edit-checkboxes-no-selected">*Vælg først et produkt</p>
                )}
              </div>
            </Col>
            <Col>
              <p className="mass-edit-subheader">
                Varenøgleord
                <button className="mass-edit-subheader-tooltip">
                  <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                  <span>Vælg de varenøgleord, som skal vises på produktet på hjemmesiden.</span>
                </button>
              </p>
              <div className="mass-edit-search">
                <Search placeholder={'Søg efter varenøgleord'} value={tagSearchTerm} onChangeFunction={handleTagSearchChange}></Search>
              </div>
              <div className="mass-edit-checkboxes">
                {isLoadingTags ? (
                  <p>Loading</p>
                ) : selectedProducts && selectedProducts.length ? (
                  tagView()
                ) : (
                  <p className="mass-edit-checkboxes-no-selected">*Vælg først et produkt</p>
                )}
              </div>
            </Col>
            <Col className="mass-edit-options">
              <Row>
                <p className="mass-edit-subheader invisible">-</p>
                <Col xs={4}>
                  <p className="mass-edit-subheader">
                    Pris
                    <button className="mass-edit-subheader-tooltip">
                      <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                      <span>
                        Angiv produktets salgspris, som vises til kunden. Sæt én pris for valgte produkter, eller procentvis op/nedjuster priserne for de valgte
                        produkter.
                      </span>
                    </button>
                  </p>
                </Col>
                <Col>
                  <select className="form-select" aria-label="Price change" onChange={(e) => setDiscountVejlType(e.target.value)}>
                    <option value="">- Ingen ændring -</option>
                    <option value="ændring">Skift til:</option>
                    <option value="stigning">Prisstigning %</option>
                    <option value="prisfald">Prisfald %</option>
                  </select>
                  {discountVejlType === 'ændring' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      step={50}
                      placeholder={'Vejl. pris'}
                      value={vejlPrice}
                      onChange={(e) => setVejlPrice(e.target.value)}
                    />
                  ) : null}
                  {discountVejlType === 'stigning' || discountVejlType === 'prisfald' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      max={100}
                      placeholder={'Indtast ændring i %'}
                      value={discountVejl}
                      onChange={(e) => {
                        Number(e.target.value) <= 100 ? setDiscountVejl(e.target.value) : setDiscountVejl('');
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <p className="mass-edit-subheader">
                    Tilbud
                    <button className="mass-edit-subheader-tooltip">
                      <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                      <span>
                        Indstil en tilbudspris, der vises i stedet for normalprisen i en begrænset periode. Sæt én tilbudspris for valgte produkter, eller
                        procentvis op/nedjuster tilbudspriserne.
                      </span>
                    </button>
                  </p>
                </Col>
                <Col>
                  <select className="form-select" aria-label="Discount change" onChange={(e) => setDiscountType(e.target.value)}>
                    <option value="">- Ingen ændring -</option>
                    <option value="ændring">Skift til:</option>
                    <option value="stigning">Prisstigning %</option>
                    <option value="prisfald">Prisfald %</option>
                  </select>
                  {discountType === 'ændring' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      step={50}
                      placeholder={'Tilbudspris'}
                      value={discountPrice}
                      onChange={(e) => setDiscountPrice(e.target.value)}
                    />
                  ) : null}
                  {discountType === 'stigning' || discountType === 'prisfald' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      max={100}
                      placeholder={'Indtast ændring i %'}
                      value={discount}
                      onChange={(e) => {
                        Number(e.target.value) <= 100 ? setDiscount(e.target.value) : setDiscount('');
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <p className="mass-edit-subheader">
                    Kostpris
                    <button className="mass-edit-subheader-tooltip">
                      <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                      <span>
                        Indtast indkøbsprisen for at få overblik over produktets profitmargin. Sæt én kostpris for valgte produkter, eller procentvis
                        op/nedjuster kostpriserne.
                      </span>
                    </button>
                  </p>
                </Col>
                <Col>
                  <select className="form-select" aria-label="Cost change" onChange={(e) => setDiscountCostType(e.target.value)}>
                    <option value="">- Ingen ændring -</option>
                    <option value="ændring">Skift til:</option>
                    <option value="stigning">Prisstigning %</option>
                    <option value="prisfald">Prisfald %</option>
                  </select>
                  {discountCostType === 'ændring' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      step={50}
                      placeholder={'Kostpris'}
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                    />
                  ) : null}
                  {discountCostType === 'stigning' || discountCostType === 'prisfald' ? (
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      max={100}
                      placeholder={'Indtast ændring i %'}
                      value={discountCost}
                      onChange={(e) => {
                        Number(e.target.value) <= 100 ? setDiscountCost(e.target.value) : setDiscountCost('');
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <p className="mass-edit-subheader">
                    Status
                    <button className="mass-edit-subheader-tooltip">
                      <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                      <span>
                        Vælg produktets synlighed på webshoppen. &#39;Kladde&#39; skjuler produktet for kunderne, mens &#39;Udgivet&#39; gør det synligt.
                      </span>
                    </button>
                  </p>
                </Col>
                <Col>
                  <select className="form-select" aria-label="Status change" onChange={(e) => setStatus(e.target.value)}>
                    <option value="">- Ingen ændring -</option>
                    <option value="draft">Kladde</option>
                    <option value="publish">Udgiv</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <p className="mass-edit-subheader">
                    Lagerantal
                    <button className="mass-edit-subheader-tooltip">
                      <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                      <span>Indtast det aktuelle antal enheder på lager, så lagerstatus vises korrekt for kunden.</span>
                    </button>
                  </p>
                </Col>
                <Col>
                  <input className="form-control" type="number" min={0} placeholder={'Skriv antal'} value={stock} onChange={(e) => setStock(e.target.value)} />
                </Col>
              </Row>
            </Col>
            <Col className="mass-edit-options">
              <p className="mass-edit-subheader">
                Attributter
                <button className="mass-edit-subheader-tooltip">
                  <img src="/fa/svgs/regular/question-circle.svg" />
                  <span>
                    Tildel attributter som størrelse, farve eller materiale til produktet. Disse bruges til at vise yderligere detaljer og kan være grundlag for
                    variationer.
                  </span>
                </button>
              </p>
              {attributes.map((attribute, index) => (
                <Row key={index}>
                  <Col>
                    <input
                      className="form-control"
                      id={`attribute-name-${index}`}
                      type="text"
                      placeholder={'Indtast navn'}
                      value={attribute.name}
                      onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                    />
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      id={`attribute-value-${index}`}
                      type="text"
                      placeholder={'Indtast værdi'}
                      value={attribute.value}
                      onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                    />
                  </Col>
                  <div className="mass-edit-options-remove">
                    <Button className="mass-edit-options-remove-btn" variant="secondary" onClick={() => handleRemoveAttribute(index)}>
                      <img src="/fa/svgs/solid/xmark.svg" />
                    </Button>
                  </div>
                </Row>
              ))}
              <Button className="mass-edit-options-btn" onClick={handleAddAttribute}>
                Tilføj attribut
              </Button>
            </Col>
          </Row>
          <div className="mt-2">
            <Button className="mass-edit-btn" variant="secondary" onClick={() => clearAll()}>
              Ryd alt
            </Button>
            <Button className="ms-2 mass-edit-btn" onClick={performMassChange} disabled={!selectedProducts || !selectedProducts.length}>
              Anvend
            </Button>
            <Button
              className="ms-2 mass-edit-btn float-end"
              variant="secondary"
              onClick={() => removeSelectedProducts()}
              disabled={selectedProducts.length === 0}
            >
              Fjern produkter
            </Button>
          </div>
        </div>
      )}

      <Table className="mt-4 product-table">
        <thead>
          <tr className="mt-5 mb-4">
            <th className="text-center" onClick={handleMarkAll}>
              <div className="edit-custom-checkbox big-checkbox ms-1">
                <span className="whitespace"></span>
                <label>
                  <input type="checkbox" onClick={(event) => event.stopPropagation()} />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    <img className={markAll ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" loading="lazy" alt="check" />
                  </span>
                </label>
              </div>
            </th>

            <th className="d-table-cell" style={{ width: '100px' }}>
              <label>Billede</label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_sku')}>
                Varenummer
                {sortField === 'vendor_products_sku' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_sku' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th style={{ width: '30%' }}>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_name')}>
                Produktnavn
                {sortField === 'vendor_products_name' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_name' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell" style={{ minWidth: '120px' }}>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_category')}>
                Kategori
                {sortField === 'vendor_products_category' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_category' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th className="d-none d-lg-table-cell" style={{ minWidth: '120px' }}>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_tags')}>
                Varenøgleord
                {sortField === 'vendor_products_tags' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_tags' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_price')}>
                Pris
                {sortField === 'vendor_products_price' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_price' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label>Status</label>
            </th>

            <th className="d-none d-xl-table-cell">
              <label>Lager</label>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {editedProducts &&
            editedProducts.slice(0, visibleCount).map((product) => {
              if (product.product_variations && product.product_variations.length !== 0) {
                return (
                  <VariableEditProducts
                    key={product.vendor_products_id}
                    product={product}
                    selectedProducts={selectedProducts}
                    setIsSimpleModalOpen={setIsSimpleModalOpen}
                    setProductUnderEdit={setProductUnderEdit}
                    webshopCategories={webshopCategories}
                    webshopTags={webshopTags}
                    removeProduct={removeProduct}
                  />
                );
              } else {
                return (
                  <tr
                    className={selectedProducts.some((p) => p.vendor_products_id === product.vendor_products_id) ? 'product-table-selected' : ''}
                    key={product.vendor_products_id}
                  >
                    <SimpleEditProducts
                      key={product.vendor_products_id}
                      product={product}
                      selectedProducts={selectedProducts}
                      setIsSimpleModalOpen={setIsSimpleModalOpen}
                      setProductUnderEdit={setProductUnderEdit}
                      productUnderEdit={productUnderEdit}
                      webshopCategories={webshopCategories}
                      webshopTags={webshopTags}
                      removeProduct={removeProduct}
                    />
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < (editedProductsCount ? editedProductsCount : 0) && (
            <>
              <Button className="show-more" onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2 show-more" onClick={handleShowAll} variant="primary">
                Vis alle ({editedProductsCount})
              </Button>
            </>
          )}
        </Col>
      </Row>

      <SimpleProductModal
        productUnderEdit={productUnderEdit}
        setProductUnderEdit={setProductUnderEdit}
        isSimpleModalOpen={isSimpleModalOpen}
        setIsSimpleModalOpen={setIsSimpleModalOpen}
        webshopCategories={webshopCategories}
        prevAndNext={getPrevAndNextProduct(productUnderEdit)}
        webshopTags={webshopTags}
      />
    </>
  );
};
