import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';

import { addWebshopProduct, updateWebshopProductImages } from 'Api';

//Models
import { ProductInterface } from '../../shared/types';
import { CustomError, ImageProductData } from './types';

import { Col, Row, Button, ProgressBar, ListGroup } from 'react-bootstrap';

//Animation
import Lottie from 'lottie-react';
import checkMark from '../../../../img/checkmark.json';

export const ConfirmImportPage: React.FC = () => {
  const [confirmedProducts, setConfirmedProducts] = useState<ProductInterface[]>([]);
  const [productImages, setProductImages] = useState<ImageProductData[]>([]);
  const [confirmedProductImages, setConfirmedProductImages] = useState<ImageProductData[]>([]);
  const [productCount, setProductCount] = useState<number>(0);
  const [time, setTime] = useState<number>(0);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<CustomError[]>([]);
  const [resultsPerPage] = useState<number>(1);

  const editedProductsCount = useLiveQuery(() => dexieDB.editedProducts.count());

  const editedProducts = useLiveQuery(() => dexieDB.editedProducts.toArray());

  useEffect(() => {
    if (!editedProductsCount || editedProductsCount === 0) {
      return;
    }

    setProductCount(editedProductsCount);
  }, [editedProductsCount]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (timerRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else if (!timerRunning) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (!timerRunning) {
      return;
    }

    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [timerRunning]);

  const stopImport = useRef(false);

  const handleStopImport = () => {
    stopImport.current = true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!editedProducts || !editedProductsCount) {
      return;
    }

    setTimerRunning(true);

    const tmpEditedProducts = [...editedProducts];
    const productChunks = [...Array(Math.ceil(tmpEditedProducts.length / resultsPerPage))].map(() => tmpEditedProducts.splice(0, resultsPerPage));

    const imagesToUpload: ImageProductData[] = [];
    for await (const products of productChunks) {
      if (stopImport.current) {
        break;
      }
      const response = await addWebshopProduct(products);
      if (response) {
        console.log(response.data);

        setConfirmedProducts((confirmedProducts) => [...confirmedProducts, ...products]);

        for await (const data of response.data) {
          if ('errors' in data) {
            setStatusText((errorMessages) => [...errorMessages, data]);
          } else {
            setProductImages((images) => [...images, data]);
            imagesToUpload.push(data);
          }
        }
      }
    }

    await handleImageUpload(imagesToUpload);

    setTimerRunning(false);
  };

  const handleImageUpload = async (images: ImageProductData[]) => {
    for await (const image of images) {
      const response = await updateWebshopProductImages([image]);

      if (response) {
        setConfirmedProductImages((confirmedImages) => [...confirmedImages, image]);

        for await (const data of response.data) {
          if ('errors' in data) {
            setStatusText((errorMessages) => [...errorMessages, ...data]);
          }
        }
      }
    }

    if (!stopImport.current) {
      await dexieDB.vendors.clear();
      await dexieDB.categories.clear();
      await dexieDB.selectedProducts.clear();
      await dexieDB.editedProducts.clear();
    }

    localStorage.setItem('import-tool-currentSubPage', 'select-vendor');
    localStorage.setItem('import-tool-canContinue', 'false');
  };

  return (
    <>
      <div className="heading-intro">
        <h1>Overførsel af produkter</h1>
        <hr className="mt-2" />
      </div>

      <Row className="confirm-import-container">
        <Col sm={12} className="text-center mt-5">
          <strong>Produkt data overførelse:</strong>
          <p>
            {confirmedProducts.length} ud af {productCount}
          </p>
        </Col>

        <Col sm={12}>
          <ProgressBar now={confirmedProducts.length} min={0} max={productCount} />
        </Col>

        {productImages.length !== 0 && (
          <>
            <Col sm={12} className="text-center mt-5">
              <strong>Upload bileder:</strong>
              <p>
                {confirmedProductImages.length} ud af {productImages.length}
              </p>
            </Col>

            <Col sm={12}>
              <ProgressBar now={confirmedProductImages.length} min={0} max={productImages.length} />
            </Col>
          </>
        )}

        <Col sm={12} className="text-center mt-3">
          {(() => {
            if (time !== 0 && !stopImport.current) {
              return (
                <div>
                  <span>{('0' + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                  <span>{('0' + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                </div>
              );
            } else if (timerRunning && stopImport.current) {
              return (
                <div>
                  <span>Stopper overførsel...</span>
                </div>
              );
            }
          })()}

          {(() => {
            if (confirmedProducts.length === productCount && confirmedProductImages.length === productImages.length) {
              return (
                <>
                  <Row className="import-finished mb-5">
                    <Lottie className="checkmark" animationData={checkMark} loop={true} />
                    <h4>Sådan! Produkterne er overført</h4>
                  </Row>
                </>
              );
            } else if (timerRunning === false && !stopImport.current) {
              return (
                <Button className="btn-primary" size="lg" onClick={() => handleSubmit()}>
                  Start overførsel
                </Button>
              );
            } else if (timerRunning === true) {
              return (
                <Button className="btn-primary" size="lg" onClick={handleStopImport}>
                  Stop overførsel
                </Button>
              );
            } else if (stopImport.current) {
              return (
                <p>
                  Du har stoppet overførslen, {confirmedProducts.length} {confirmedProducts.length === 1 ? ' produkt' : ' produkter'} er blevet overført. Klik
                  på &quot;Tilbage&quot; for at starte igen.
                </p>
              );
            }
          })()}
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="mt-5">
          {statusText.map((element) => {
            if (element && element.productId && element.errors) {
              const productId = element.productId;
              const productName = element.productName;

              const errorListItems = element.errors.map((error, index) => {
                const variationSku = error.variationSku;
                const message = error.message;

                return (
                  <ListGroup.Item key={index}>
                    Produkt navn: {productName} {variationSku ? `- Variation SKU: ${variationSku}` : ``} - {message}
                  </ListGroup.Item>
                );
              });

              return <ListGroup key={productId}>{errorListItems}</ListGroup>;
            } else {
              Sentry.setExtras({
                Element: element,
                'All statuses text': statusText,
                'All confirmed products': confirmedProducts,
              });
              Sentry.captureException(new Error('ProjectId not defined'));
              return null;
            }
          })}
        </Col>
      </Row>
    </>
  );
};
