import React, { useEffect, useState } from 'react';
import { useUsersContext } from 'contexts/users';
import { UsersActions } from 'contexts/users/types';
//Models
import { UsersIntergrationsInterface } from './types';
import { CategoryInterface } from 'pages/import-products/shared/types';
//Api
import { getWebshopConfig, setWebshopConfig, getWebshopProductCategories } from 'Api';
//Styles
import { Row, Col, Card, Form, Button, Spinner, Toast } from 'react-bootstrap';
import debugLog from 'lib/debug';

export const WooCommerceConfigPage: React.FC = () => {
  const { usersDispatch } = useUsersContext();
  const [config, setConfig] = useState<UsersIntergrationsInterface>({
    users_integrations_url: '',
    users_integrations_key: '',
    users_integrations_secret: '',
  });
  const [statusText, setStatusText] = useState('');
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [webshopCategories, setWebshopCategories] = useState<CategoryInterface[]>([]);
  const [showKey, setShowKey] = useState<boolean>(false);
  const [showSecret, setShowSecret] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const getWebshopConfigHandler = async (): Promise<void> => {
      const response = await getWebshopConfig();
      if (isMounted) {
        if (response) {
          setConfig(response.data);
        }
      }
    };

    const getWebshopProductCategoriesHandler = async () => {
      setLoading(true);

      const response = await getWebshopProductCategories();
      if (isMounted) {
        if (response) {
          if (response.data.error) {
            setStatus(false);
            setStatusText('Connection status: ' + response.data.error);
          } else {
            setStatus(true);
            setStatusText('Connection status: ' + response.status + ' ' + response.statusText);
            setWebshopCategories(response.data);
          }
        }
      }
      setLoading(false);
    };

    getWebshopConfigHandler();
    getWebshopProductCategoriesHandler();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({ ...config, [event.target.id]: event.target.value });
    debugLog('Value changed: ' + event.target.id + ' ' + event.target.value, { enable: false });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    const response = await setWebshopConfig(config);
    if (response) {
      if (response.data.error) {
        setStatus(false);
        setStatusText('Connection status: ' + response.status + ': ' + response.data.status);
      } else {
        setConfig(config);
        setStatus(true);
        setStatusText('Connection status: ' + response.status + ': ' + response.data.status);
        if (response.data.token) {
          usersDispatch({ type: UsersActions.LOGIN, payload: response.data.token });
        }
      }
    } else {
      setStatus(false);
      setStatusText('400: Bad Request');
    }

    setLoading(false);
  };

  function showSecretBtn() {
    setShowSecret(!showSecret);
  }

  function showKeyBtn() {
    setShowKey(!showKey);
  }

  return (
    <>
      <Row className="integration">
        <Col className="p-0">
          <div>
            <h1 className="integration-header">WooCommerce integration</h1>
            <hr className="mt-2" />
          </div>
          <Form className="integration-form">
            <Form.Group className="integration-form-input" controlId="users_integrations_url">
              <Form.Label>URL</Form.Label>
              <Form.Control type="url" placeholder="Indtast URL" value={config.users_integrations_url} onChange={handleValueChanged} />
            </Form.Group>
            <Form.Group className="integration-form-input" controlId="users_integrations_key">
              <Form.Label>ConsumerKey</Form.Label>
              <Form.Control
                type={showKey ? 'text' : 'password'}
                placeholder="Indtast CustomerKey"
                value={config.users_integrations_key}
                onChange={handleValueChanged}
              />
              <Form.Check type="checkbox" label="Vis key" className="mt-2" onClick={showKeyBtn} />
            </Form.Group>
            <Form.Group className="integration-form-input" controlId="users_integrations_secret">
              <Form.Label>ConsumerSecret</Form.Label>
              <Form.Control
                type={showSecret ? 'text' : 'password'}
                placeholder="Indtast ConsumerSecret"
                value={config.users_integrations_secret}
                onChange={handleValueChanged}
              />
              <Form.Check type="checkbox" label="Vis secret" className="mt-2" onClick={showSecretBtn} />
            </Form.Group>

            <Button className="integration-form-btn" type="submit" onClick={handleFormSubmit}>
              {loading ? (
                <>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </>
              ) : (
                'Gem'
              )}
            </Button>
          </Form>

          <div className="mt-4">
            {loading ? (
              <>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </>
            ) : (
              <span className={`integration-status ${status ? 'success' : 'failed'}`}>{statusText}</span>
            )}
          </div>

          <div className="mt-5">
            <div>
              <h1 className="integration-header">WooCommerce kategorier</h1>
              <hr className="mt-2" />
            </div>

            <Toast className="mb-4">
              <Toast.Header closeButton={false}>
                <strong className="me-auto">IT Stack</strong>
                <small>Note</small>
              </Toast.Header>
              <Toast.Body>Hej! Husk at oprette alle kategorier i din webshop før du starter dit import.</Toast.Body>
            </Toast>
            <div className="integration-categories">
              {webshopCategories?.length > 0
                ? webshopCategories.map((category) => <Card key={category.id}>{category.name}</Card>)
                : 'Kategorier vises når du har oprettet forbindelse til WooCommerce'}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
